import type { FeaturesToggles } from 'src/config/features-toggle';

import { isProd } from 'src/utils/environment';

const config: FeaturesToggles = {
  SIGN_IN_RATE_LIMIT: false,
  CONTACT_FORM_AGENT_PHONE_PRIORITY_OVER_AGENCY: true,
  AUCTIONS_ENABLED: false,
  AGENCIES_HOMEPAGE_SEARCH_ENABLED: true,
  VIRTUAL_TOUR_FILTER: false,
  COMMERCIAL_ACTIVITY_TYPE_FILTER: true,
  SOCIALS_ENABLED: true,
  EVALUATE_BUILDING: false,
  BEDROOM_SEARCH_ENABLED: true,
  ONLY_COMPLETE_BEDROOM_SEARCH_ENABLED: false,
  COMMERCIAL_SEARCH_AS_CATEGORIES: false,
  LABORATORY_SEARCH_ENABLED: true,
  MOBILE_UPSELLING: true,
  POI_ENABLED: false,
  LISTING_FIRST_MOSAIC_IMAGE_WATERMARK: false,
  BRAZE: true,
  BRAZE_DESKTOP_ANON_USERS: !isProd,
  REAL_ESTATE_3D_MAP_ENABLED: true,
  SELLER_LEAD: false,
  PUBLISH_MOBILE_BTN: false,
  DOSSIERS_ENABLED: false,
  ASSOCIATED_SERVICES: false,
  SEARCH_MODAL_DISABLE_FILTER_BY_PROPERTY_TYPE: false,
  GENERIC_IMAGE: false,
  PREFERRED_LOCALE_REDIRECT: false,
  SEARCH_LIST_SHOW_PROJECT_COMMERCIAL_SURFACE: true,
  ENABLE_ALTERNATE_AGENCY_SIGNUP: false,
  VISIT_REQUEST_ENABLED: false,
  VISIT_REQUEST_MULTIPLE_CHOICE_DAYS: false,
  VISIT_REQUEST_MULTIPLE_CHOICE_TIMETABLES: false,
  DOWNLOAD_PROMOTIONAL_BANNER: false,
  COUNTRY_SEARCH_SHORTCUT: false,
  DISCOVER_TITLE: false,
  MAIN_PARTNER_HOME: false,
  AGENCIES_EXPERT_COVER: false,
  AGENCIES_EXPERT_ZONE_TITLE_ENABLED: false,
  AGENCIES_EXPERT_SCARSITY_ENABLED: false,
  LISTINGS_METRO_SEARCH: true,
  REGION_SEARCH_ENABLED: false,
  COUNTRY_SEARCH_ENABLED: false,
  LISTINGS_DISTANCE_SEARCH: true,
  INTERNATIONAL_SEARCH_ENABLED: false,
  INTERNATIONAL_SEARCH_ASK_TO_AGENCIES_ENABLED: true,
  INTERNATIONAL_SEARCH_ONLY_RESIDENTIAL: false,
  INTERNATIONAL_SEARCH_DISABLE_AUCTIONS: false,
  INTERNATIONAL_SEARCH_DISABLE_AGENCIES: false,
  INTERNATIONAL_SEARCH_REMOVE_HEADER_LINK: false,
  INTERNATIONAL_HIDE_MORTGAGE: false,
  INTERNATIONAL_LABELS_ENABLED: false,
  SHOW_MORTGAGE_WIDGET: true,
  SHOW_IMMOTOP_MORTGAGE_WIDGET: false,
  MORTGAGE_AVAILABLE: false,
  LISTING_SHOW_SURFACES_CONSTITUTION: true,
  LALUX_ASSURANCE_ENABLED: false,
  SHOW_HEADER_PUBLISH_BTN: true,
  SHOW_SEARCH_LIST_PRICE_CHART: true,
  AUTH_POST_CONTACT: false,
  AUTH_POST_SAVE_SEARCH: false,
  REALESTATE_MODAL_NOT_LOGGED: false,
  PUBLISHADSPLACEHOLDER: true,
  REAL_ESTATE_PROJECTS_REDIRECT: false,
  MESSAGING_IN_LAST_SEARCH: false,
  MESSAGING_IN_LAST_THINGS_ENABLED: true,
  REALTIME_NOTIFICATIONS_ENABLED: true,
  REAL_ESTATE_SURROUNDINGS_SECTION: false,
  ENABLE_AUTH_CSRF_TOKEN: false,
  DETAIL_CLIENT_TRACK_VISIT_ENABLED: true,
  CITY_GUIDE_EVALUATION_HREF_OVERRIDE_ENABLED: true,
  AGENCIES_HOMEPAGE_SEARCH_LOCALITY_DISABLED: false,
  ENABLE_MORTGAGE_ALTERNATIVE_URL: true,
  AGENCY_BREADCRUMBS: true,
  AGENCY_URL_KEYURL: true,
  NUMBER_ONE_LINK: false,
  TRANSLATE_GA4_PROPS: true,
  ENABLE_UNIQUE_AUTH_MODAL: !isProd,
  PREVENT_BOT_TRACKING: false,
  SHOW_OUTER_ZONE_AGENCY_CAROUSEL: false,
  SEGMENT_TRACKING: true,
  SEGMENT_BATCHING: false,
  FORCE_INTERNATIONAL_PHONE_NUMBER: false,
  AGENCY_LIST_NATION_SEARCH: false,
  AGENCY_LIST_ADV: false,
  SHOW_MULTI_CONTACT_FORM: false,
  AGENCY_LIST_MAP_LAYOUT: true,
  AGENCY_LIST_SEARCH_AGENCIES: false,
  ENABLE_FRANCHISING_PAGE: false,
  ENABLE_AGENTS_PAGE: false,
  SEARCH_LIST_BOTTOM_SERVICES: false,
  AGENCIES_EXPERT_HOME: false,
  AGENCIES_EXPERT_SEARCH_LIST: false,
  AGENCIES_EXPERT_SORT_BY_BUNDLE: false,
  AGENCIES_EXPERT_SEARCH_LIST_PROVINCE: false,
  AGENCIES_EXPERT_SEARCH_LIST_MAP: false,
  MACROAREAS_SEARCHES: false,
  SEARCH_AGENCIES_COUNTRY: false,
  AGENCIES_EXPERT_MARKERS_SORT_BY_BUNDLE: false,
  ACTIVE_SEARCH: false,
  ACTIVE_SEARCH_FULL_FILTERS: false,
  SNOWFLAKES: false,
  SEARCH_ON_MAP_BUTTONS: true,
  SEARCH_ON_MAP_AREA: true,
  SEARCH_ON_MAP_ISOCHRONOUS: !isProd,
  FORCE_AGENCY_LOGO_ON_SEARCH_LIST: isProd,
  FIX_ALTERNATE_POLYGONS: false,
  AGENCY_NAME_SEARCH: true,
  TROVAKASA_API: true,
  REAL_ESTATE_PROPERTIES_IN_COST_AND_ENERGY: true,
  REAL_ESTATE_ENERGY_CLASS_COLOR_CUSTOM: true,
  LINK_AGENCY_LOGO_ON_SEARCH_LIST: !isProd,
  GARDEN_FILTER_ON_FILTER_BAR: false,
  CONTACTED_LISTING_INFO: false,
  NEW_INDOMIOGR_REDIDENTIAL_LISTS: false,
  GEOGRAPHY_SERVICE_API_GEOGRAPHY_LIST: true,
  GEOGRAPHY_SERVICE_API_GEOGRAPHY_INFO: true,
  REACT_GEOGRAPHY_SEARCH: false,
  REACT_GEOGRAPHY_SEARCH_ALPHA_ALERT: false,
  LISTING_SEARCH_NEW_SORTING: false,
  GROWTHBOOK: false,
  GROWTHBOOK_PROXY_TEST: false,
  MY_HOME: false,
  SEARCH_TAB_MY_HOME: false,
  SEGMENT_TRACKING_PARTNERS_COOKIE: true,
  FULLSTORY: false,
  LISTING_SECTIONS_VIEWED: false,
  LISTING_DETAIL_MAP_MEDIA_TAB: false,
  AGENCIES_EXPERT_BY_DISTANCE_OR_AREA_SEARCH: false,
  LISTING_DETAIL_GALLERY_ACTIONS: true,
  LISTING_DETAIL_NEW_MORTGAGE_BANNER: true,
  LISTING_DETAIL_NEW_PARTNER_BANNER: false,
};

export default config;
